import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
import { I18nextProvider } from '@wix/yoshi-flow-editor';
import App from '../../../common/components/app';
import AppLoaded from '../../../common/components/app-loaded';
import PermissionsProvider from '../../../common/components/permissions-provider';
import PopoverRoot from '../../../common/components/popovers/popover-root';
import ResponsiveListener from '../../../common/components/responsive-listener/responsive-listener';
import { connect } from '../../../common/components/runtime-context';
import { withReduxStore } from '../../../common/components/runtime-context/with-redux-store';
import { getIsCreatedWithResponsiveEditor } from '../../../common/selectors/app-settings-selectors';
import { initI18n } from '../../../common/services/init-i18n';
import { isInWix } from '../../../common/services/is-in-wix';
import {
  getLanguage,
  isEditor,
  getIsMobile,
  getIsRTL,
  isSeo as getIsSeo,
  getIsRendered,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import MessageRoot from '../messages/message-root';
import ModalRoot from '../modals/modal-root';
import MyPosts from '../my-posts';
import MyPostsFrame from '../my-posts-frame';
import styles from './app-root.scss';
import RichContentViewerLoader
  from '../../../rich-content/components/rich-content-viewer-loader/rich-content-viewer-loader';

const MyPostsPage = () => (
  <MyPostsFrame>
    <MyPosts />
    <AppLoaded />
    <RichContentViewerLoader />
  </MyPostsFrame>
);

class AppRoot extends React.Component {
  componentDidMount() {
    if (!isInWix()) {
      document.documentElement.classList.add('enable-scroll');
    }
    if (this.props.isEditor && this.props.createdWithResponsiveEditor) {
      document.body.style.height = 'auto';
    }
  }

  getI18nConfig = () => {
    const { language, translations } = this.props;

    if (!this.i18nConfig) {
      this.i18nConfig = initI18n(language, translations);
    }

    return this.i18nConfig;
  };

  render() {
    const { isMobile, isRtl, isSeo, isRendered } = this.props;
    const i18n = this.getI18nConfig();

    if (!isRendered) {
      return null;
    }

    return (
      <ResponsiveListener
        dataHook="my-posts-page-root"
        className={classNames(styles.baseStyles, styles.root)}
      >
        <PermissionsProvider>
          <I18nextProvider i18n={i18n}>
            <TPAComponentsProvider
              value={{ mobile: isMobile, rtl: isRtl, seo: isSeo }}
            >
              <App
                main={<MyPostsPage />}
                modalRoot={<ModalRoot />}
                messageRoot={<MessageRoot />}
                popoverRoot={<PopoverRoot />}
              />
            </TPAComponentsProvider>
          </I18nextProvider>
        </PermissionsProvider>
      </ResponsiveListener>
    );
  }
}

AppRoot.propTypes = {
  language: PropTypes.string,
  translations: PropTypes.array,
  isEditor: PropTypes.bool,
  isMobile: PropTypes.bool,
  isRtl: PropTypes.bool,
  isSeo: PropTypes.bool,
  createdWithResponsiveEditor: PropTypes.bool,
  isRendered: PropTypes.bool,
};

const mapRuntimeToProps = (state) => ({
  language: getLanguage(state),
  translations: getTranslations(state),
  isEditor: isEditor(state),
  isMobile: getIsMobile(state),
  isRtl: getIsRTL(state),
  isSeo: getIsSeo(state),
  createdWithResponsiveEditor: getIsCreatedWithResponsiveEditor(state),
  isRendered: getIsRendered(state),
});

export default withReduxStore(connect(mapRuntimeToProps)(AppRoot));
